<template>
  <Toolbar class="py-4">
    <template #left>
      <div>
        <p class="_heading-2">{{$store.state.photos.data.name}}</p>
        <span class=""><span v-t="'photos_page.photos'" class="_text-gray"></span>: {{$store.state.photos.data.photos_count}}</span>
        <span class=" ml-3"><span v-t="'photos_page.processed_photos'" class="_text-gray"></span>: {{$store.state.photos.data.processed_photos_count}}</span>
      </div>
      <label class="p-button ml-6">
        <input type="file" name="" id="" :disabled="false" class="p-upload-input" multiple @change="uploadFile">
        <span v-t="'vehicles_page.upload_photo'" />
      </label>
      <Button @click="download" class="p-button-outlined p-button-gray p-button-only-icon ml-3">
        <Icon name="download" />
      </Button>
      <!-- <Button @click="deleteAlbum" class="p-button-outlined p-button-gray p-button-only-icon ml-3">
        <Icon name="trash" />
      </Button> -->
    </template>
    <template #right>
      <div>
        <p class="_heading-2" v-t="'photos_page.markup'" />
        <span class=""><span v-t="'photos_page.count'" class="_text-gray"></span>: {{$store.state.photos.data.datasets.length}}</span>
      </div>
      <label class="p-button ml-6">
        <input type="file" name="" multiple id="" :disabled="false" class="p-upload-input" @change="datasetUpload">
        <span v-t="'photos_page.upload_markup'" />
      </label>
      <Button @click="datasetDownload" :disabled="!$store.state.photos.data.datasets.length" class="p-button-outlined p-button-gray p-button-only-icon ml-3">
        <Icon name="download" />
      </Button>
    </template>
  </Toolbar>
  <div class="grid">
    <div class="col-3" v-for="item in $store.state.photos.data.photos.data" :key="item.id">
      <div :class="$style.card">
        <svg :class="$style.substrate" class="_img-base" width="1920" height="1440" viewBox="0 0 1920 1440" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="1920" height="1440" fill="#ECEDF3"/></svg>
        <img v-lazy="item.thumbnail" class="_img-base" :class="$style.img" alt="">
        <div :class="$style.loading"><ProgressSpinner /></div>

        <div :class="$style.checkboxGroup">
          <div class="flex mb-2">
            <span v-t="'photos_page.selected_to_download'"></span>
            <Checkbox :class="$style.checkbox" class="ml-auto" v-model="item.isSelected" :value="item.id" :binary="true" />
          </div>
          <div class="flex">
            <span v-t="'photos_page.processed'"></span>
            <Checkbox :class="$style.checkbox" @change="markProcessed" class="ml-auto" :disabled="item.processed && !item.can_remove_mark" v-model="item.processed" :value="item.id" :binary="true" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Paginator
    :currentPage="$store.state.photos.data.photos.current_page || $route.query.page || 1"
    :total="$store.state.photos.data.photos.total"
    :lastPage="$store.state.photos.data.photos.last_page"
    :perPage="$store.state.photos.data.photos.perPage"
    :count="$store.state.photos.data.photos.to"
  />
</template>

<script>

import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import { useI18n } from 'vue-i18n';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import ProgressSpinner from 'primevue/progressspinner';

import Toolbar from 'primevue/toolbar';

import { Icon } from '../../components/ui'
import { Paginator } from '../../components/common'
import { useRoute, useRouter } from 'vue-router';

export default {
  components: {
    Button,
    Icon,
    Toolbar,
    Checkbox,
    Paginator,
    ProgressSpinner,
  },
  setup() {
    const store = useStore()
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const { t }= useI18n()

    const isShow = ref(false)
    const downloadIDs = computed(() => {
      const filtered = store.state.photos.data.photos.data.filter(({ isSelected }) => isSelected)
      if (filtered.length) {
        return filtered.map(({ id }) => id)
      }
      return store.state.photos.data.photos_ids
    })
    const processedIDs = computed(() => {
      const ids = {}
      store.state.photos.data.photos.data.forEach(({ id, processed }) => {
        ids[id] = processed
      })
      return ids
    })
    const datasetIDs = computed(() => store.state.photos.data.datasets.map(({ id }) => id))

    store.dispatch('photos/reqBase', { sectionID: route.params.photosID, per_page: 32, page: route.query.page, })
    watch(route, () => {
      store.dispatch('photos/reqBase', { sectionID: route.params.photosID, per_page: 32, page: route.query.page })
    })

    const download = () => {
      store.dispatch('photos/reqDownloadPhoto', { body: { photos: downloadIDs.value }, toast, t })
    }
    const markProcessed = () => {
      // const files = {}
      // Object.entries(processedIDs.value).forEach(([key, value]) => {
      //   return files[`photo[${key}]`] = value
      // })
      // console.log(files);
      store.dispatch('photos/reqMarkProcessedPhoto', { body: processedIDs.value, toast, t })
    }

    function uploadFile(event) {
      const files = {}
      for (let index = 0; index < event.target.files.length; index++) {
        files[`photo[${index}]`] = event.target.files[index];
      }
      const body = {
        section_id: route.params.photosID,
        ...files
      }
      store.dispatch('photos/reqUploadPhoto', { body, toast, t })
    }

    const datasetUpload = (event) => {
      const files = {}
      for (let index = 0; index < event.target.files.length; index++) {
        files[`file[${index}]`] = event.target.files[index];
      }
      const body = {
        section_id: route.params.photosID,
        ...files
      }
      store.dispatch('photos/reqDatasetUpload', { body, toast, t })
    }
    const datasetDownload = () => {
      store.dispatch('photos/reqDatasetDownload', { sectionID: route.params.photosID, toast, t })
    }
    const deleteAlbum = () => {
      store.dispatch('photos/reqDeleteAlbum', { body: { sectionID: route.params.photosID, }, toast, t, router })
    }
    return {
      isShow,
      download,
      uploadFile,
      markProcessed,
      datasetUpload,
      datasetDownload,
      deleteAlbum,
    }
  }
}
</script>

<style lang="scss" module>

.card {
  position: relative;
  border-radius: $radius;
}

.substrate {
  height: auto;
  border-radius: $radius;
}

.img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $radius;
  &[src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"] ~ {
   .loading {
      display: flex;
    }
  }
}

.loading {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.checkboxGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 24px;
  background-color: rgba(#fff, 0.7);
}

.checkbox {
  /* background-color: rgba(#fff, 0.8);
  padding: 6px;
  border-radius: $radius-small; */
}


</style>